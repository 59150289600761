import React from 'react';
import Servidor from './Servidor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, SwipeableDrawer, List, Divider, FormControl, InputLabel, Input, Checkbox, Icon } from '@material-ui/core';

class RegisterWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
      maquinaria: {
        action: "guardar",
        nombre: "",
        num: "",
        descripcion: "",
        foto_maq: "",
        archivo: "",
      }
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.list = this.list.bind(this);
    this.manejarCambio = this.manejarCambio.bind(this);
    this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
    this.toggleInputOtraMaquina = this.toggleInputOtraMaquina.bind(this);
  }

  toggleInputOtraMaquina() {
    this.setState(prevState => ({
        mostrarInputOtraMaquina: !prevState.mostrarInputOtraMaquina
    }));
}

  toggleDrawer(anchor, open) {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      this.setState({ ...this.state, [anchor]: open });
    };
  }

  list(anchor) {
    return (
      <div
        className="has-background-dark p-2"
        style={{ width: '100%', height: '100%' }}
        role="presentation"
        onClick={this.toggleDrawer(anchor, false)}
        onKeyDown={this.toggleDrawer(anchor, false)}
      >
        <List style={{ height: '100%' }}>
            <form className="card-body p-3" onSubmit={this.manejarEnvioDeFormulario} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <h1 className="card-header title"><p className="card-header-title is-size-4">Registrar Maquina</p></h1>
                <ToastContainer></ToastContainer>
                <div className="control">
                    <select
                        placeholder="Nombre"
                        name='nombre'
                        id="nombre"
                        onChange={this.manejarCambio}
                        value={this.state.maquinaria.nombre}
                        className="input is-rounded is-hovered is-primary"
                    >
                        <option value="">Elige una Opción</option>
                        <option value="Compresor">Compresor</option>
                        <option value="Banda descarga de Papas">Banda descarga de Papas</option>
                        <option value="Banda descarga de Plátanos">Banda descarga de Plátanos</option>
                        <option value="Carcamo Área de Plátanos">Carcamo Área de Plátanos</option>
                        <option value="Carcamo Área de Papas">Carcamo Área de Papas</option>
                        <option value="Limpiadora de Cebolla">Limpiadora de Cebolla</option>
                        <option value="Banda Alimentadora de Papas">Banda Alimentadora de Papas</option>
                        <option value="Enjuague y Cepillos Papas">Enjuague y Cepillos Papas</option>
                        <option value="Banda Rodillo Aluminio">Banda Rodillo Aluminio</option>
                        <option value="Patines">Patines</option>
                        <option value="Difusores">Difusores</option>
                        <option value="Sistema Hidraulico">Sistema Hidraulico</option>
                        <option value="Alumbrado General">Alumbrado General</option>
                        <option value="Elevador">Elevador</option> 
                    </select>
                </div>
                <div>
                <span style={{ cursor: 'pointer', color: 'white', fontWeight: 'bold' }}><Checkbox text="Insertar otra maquinaria" onChange={this.toggleInputOtraMaquina} /> Insertar otra maquinaria</span>
                </div>
                {this.state.mostrarInputOtraMaquina && (
                    <FormControl id="nombre_input" style={{ width: '100%' }}>
                        <InputLabel htmlFor="nombre_input">Otra Maquina</InputLabel>
                        <Input
                            id="nombre"
                            name="nombre"
                            className='has-text-light'
                            type="text"
                            onChange={this.manejarCambio}
                            value={this.state.maquinaria.nombre}
                        />
                    </FormControl>
                )}
                <div className="control mt-3">
                    <input 
                        required 
                        placeholder="Numero" 
                        type="number" 
                        name='num'
                        id="num" 
                        onChange={this.manejarCambio} 
                        value={this.state.maquinaria.num || ''} 
                        className="input is-rounded is-hovered is-primary" 
                    />
                </div>
                <div className="control mt-3">
                    <input 
                        required
                        placeholder="Descripción" 
                        type="text" 
                        name='descripcion'
                        id="descripcion" 
                        onChange={this.manejarCambio} 
                        value={this.state.maquinaria.descripcion || ''} 
                        className="input is-rounded is-hovered is-primary" 
                    />
                </div>
                <div className="control mt-3">
                    <input
                        placeholder="Foto"
                        type="file"
                        name="foto_maq"
                        id="foto_maq"
                        onChange={this.manejarCambio}
                        className="input is-rounded is-hovered is-primary"
                        accept=".jpg, .jpeg, .png"
                    />
                    <span style={{ color: 'white', fontWeight: 'bold' }}>Formato: .jpg, .jpeg, .png <br /> También puede guardar sin imagen</span>
                </div>
                <input type="hidden" name="action" value={this.state.maquinaria.action || 'guardar cambios'} />
                <Divider style={{ backgroundColor: 'white' }} className='mt-4' />
                <div className="form-group">
                    <button className="button is-primary is-rounded is-inverted mt-3"><i className="fas fa-save mr-2"></i> Guardar</button>
                    &nbsp;
                </div>
            </form>
        </List>
      </div>
    );
  }

  render() {
    return (
      <div id='add' className='button is-primary is-inverted is-outlined btn-icon'>
        <ToastContainer />
        <Button className='button' style={{ color: 'aqua' }} onClick={this.toggleDrawer('left', true)}><Icon className="fas fa-cogs icon"></Icon></Button>
        <SwipeableDrawer
          anchor={'left'}
          open={this.state['left']}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          {this.list('left')}
        </SwipeableDrawer>
      </div>
    );
  }

    async manejarEnvioDeFormulario(evento) {
      evento.preventDefault();

      const archivoSeleccionado = evento.target.foto_maq.files[0];
      let archivoBase64 = '';

      if (archivoSeleccionado) {
        const reader = new FileReader();
        reader.onloadend = () => {
          archivoBase64 = reader.result;
        }
        reader.readAsDataURL(archivoSeleccionado);
      }

      const cargaUtil = JSON.stringify({
        action: this.state.maquinaria.action,
        nombre: this.state.maquinaria.nombre,
        num: this.state.maquinaria.num,
        descripcion: this.state.maquinaria.descripcion,
        foto_maq: archivoSeleccionado ? archivoSeleccionado.name : '',
        archivo: archivoBase64,
      });

      const respuesta = await fetch(`${Servidor.url}/controller/ControladorMaquinaria.php`, {
        method: 'POST',
        body: cargaUtil
      });

      const exitoso = await respuesta.json();
      if (exitoso === true) {
        toast('Maquinaria Guardada 🛠️ ', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          maquinaria: {
            action: 'guardar',
            nombre: '',
            num: '',
            descripcion: '',
            foto_maq: '',
            archivo: '',
          }
        });
      } else {
        toast.error('Error al Guardar 🛠️');
      }
  }

  manejarCambio(evento) {
      const clave = evento.target.id;
      let valor = evento.target.value;
      this.setState(state => {
          const maquinariaActualizada = state.maquinaria;
          if (clave === 'num') {
              valor = parseFloat(valor);
          }
          if (clave === 'foto') {
              const archivoSeleccionado = evento.target.files[0];
              console.log('Archivo seleccionado:', archivoSeleccionado);
              // Aqui puedes realizar más acciones con el archivo, como mostrar su nombre, tamaño, etc.
          } else {
              maquinariaActualizada[clave] = valor;
          }
          return { maquinaria: maquinariaActualizada };
      });
  }
}

export default RegisterWindow;
